import './Offers.less';

export const Offers = () => {
  return (
    <div className="container">
      <section className="offers">
        <h2 className="offers__title">Спецпредложения</h2>
        <div className="offers__list">
          <div className="offer">
            <h3 className="offer__title">Тариф длительное проживание</h3>
            <p className="offer__description">Выгодное бронирование на длительный период:
            </p>
            <ul className="offer__list">
              <li className="offer__list-item">15 дней - скидка до 30%</li>
              <li className="offer__list-item">30 дней - скидка до 40%</li>
            </ul>
          </div>
          <div className="offer">
            <h3 className="offer__title">Тариф выходного дня</h3>
            <p className="offer__description">С пятницы по понедельник отдыхайте и экономьте с «Тарифом выходного дня». Скидка на проживание в комфортабельных номерах любой категории - 10%, включенный вкусный завтрак. </p>
          </div>
        </div>
      </section>
    </div>
  )
}
