import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, {Settings} from "react-slick";
import {Room} from "../../components";
import wifiIcon from "/assets/icons/wifi-icon.svg";
import parkingIcon from "/assets/icons/parking-icon.png";
import washingMachineIcon from "/assets/icons/washing-machine-icon.png";
import './Main.less';
import {useEffect, useRef, useState} from "react";
import {RoomsService, IRoom} from "../../services";
import {Link} from "react-router-dom";
import {RoutesEnum} from "../../routes.ts";

const sliderSettings: Settings = {
  variableWidth: true,
  arrows: false,
  dots: true,
}

export function Main() {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const sliderRef = useRef<Slider | null>(null);

  function handleSlickPrev() {
    sliderRef.current?.slickPrev();
  }

  function handleSlickNext() {
    sliderRef.current?.slickNext();
  }

  useEffect(() => {
    const roomsService = new RoomsService();

    roomsService
      .getRooms()
      .then(rooms => setRooms(rooms))
      .catch(err => {
        console.error(err);
      })
  }, [])

  return (
    <>
      <section className="banner">
        <div className="banner__container">
          <h2 className="banner__title">Забронировать номер в мини-отеле Тарлеон</h2>
          <Link to={RoutesEnum.ROOMS} className="banner__form-submit">Забронировать</Link>
        </div>
      </section>
      <section className="container rooms">
        <div className="rooms__header">
          <h2 className="rooms__title">Номера</h2>
          <div className="slider-arrows">
            <button onClick={handleSlickPrev} className="slider-arrows_prev" type='button'>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
                <path
                  d="M1.74078 5.99966L7 0.974334L5.97974 -8.91942e-08L0.211469 5.51249C0.14443 5.57648 0.0912486 5.65248 0.0549632 5.73613C0.0186778 5.81978 5.32453e-07 5.90945 5.24537e-07 6C5.1662e-07 6.09055 0.0186777 6.18022 0.0549631 6.26387C0.0912485 6.34752 0.14443 6.42352 0.211469 6.48751L5.97974 12L7 11.025L1.74078 5.99966Z"
                  fill="#808080"/>
              </svg>
            </button>
            <button onClick={handleSlickNext} className="slider-arrows_next" type='button'>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
                <path
                  d="M5.25922 6.00034L0 11.0257L1.02026 12L6.78853 6.48751C6.85557 6.42351 6.90875 6.34752 6.94504 6.26387C6.98132 6.18022 7 6.09055 7 6C7 5.90945 6.98132 5.81978 6.94504 5.73613C6.90875 5.65248 6.85557 5.57648 6.78853 5.51249L1.02026 0L0 0.975021L5.25922 6.00034Z"
                  fill="#808080"/>
              </svg>
            </button>
          </div>
        </div>
        <Slider ref={slider => {
          sliderRef.current = slider;
        }} {...sliderSettings} className="rooms__list">
          {rooms.map((room) => (
            <Room {...room} key={room.id}/>
          ))}
        </Slider>
      </section>
      <section className="container">
        <ul className="benefits">
          <li className="benefits__item">
            <h4 className="benefits__item-title">Бесплатный Wi-Fi на всей территории отеля</h4>
            <img className="benefits__item-icon" src={wifiIcon} alt="иконка преимущества"/>
          </li>
          <li className="benefits__item">
            <h4 className="benefits__item-title">Собственная парковка</h4>
            <img className="benefits__item-icon" src={parkingIcon} alt="иконка преимущества"/>
          </li>
          <li className="benefits__item">
            <h4 className="benefits__item-title">Собственная прачечная с услугами чистки и глажки одежды</h4>
            <img className="benefits__item-icon" src={washingMachineIcon} alt="иконка преимущества"/>
          </li>
        </ul>
      </section>
      <section className="container">
        <div className="about">
          <div className="about__content">
            <h2 className="about__content-title">Об отеле</h2>
            <p className="about__content-text">Мини-отель «ТАРЛЕОН» находится в историческом районе Москвы
              "Лефортово".
              Недалеко от мини-отеля
              расположены
              такие достопримечательности как Музей-заповедник "Лефортово", Лефортовский парк, Немецкая слобода,
              Екатерининский дворец. В 2-х минутах ходьбы находится бассейн, большой торговый комплекс с кинотеатром,
              магазинами и уютными кафе, где Вы сможете недорого и вкусно поесть.</p>
            <p className="about__content-text">В самом отеле есть бесплатный WiFi, ресторан, зоны для семейного
              отдыха, игровые зоны и химчистка. Стойка регистрации работает круглосуточно.</p>
          </div>
          <iframe
            className="about__map"
            title="map"
            src="https://yandex.ru/map-widget/v1/?ll=37.721604%2C55.739222&mode=poi&poi%5Bpoint%5D=37.719111%2C55.738974&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D1688120118&z=14.12"
            width="583" height="457" frameBorder="0"></iframe>
        </div>
      </section>
      <section className="container">
        <h2 className="title">Виртуальный тур</h2>
        <iframe
          className="virtual-tour__image"
          src="https://yandex.ru/map-widget/v1/org/mini_otel_tarleon/1688120118/panorama/?from=mapframe&ll=37.719603%2C55.739483&panorama%5Bdirection%5D=219.249121%2C-4.727026&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=37.719776%2C55.739287&panorama%5Bspan%5D=118.288330%2C60.000000&source=mapframe&um=constructor%3Abb0971d7458537a73508e100811bf585b9be44a57240c52be6c22426d28f5b09&utm_source=mapframe&z=16.84"
          width="100%" height="600" frameBorder="0" allowFullScreen={true}></iframe>
      </section>
    </>
  )
}
