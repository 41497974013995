import {useNavigate, Link} from 'react-router-dom';
import logoFooter from "/assets/icons/logo-footer.svg";
import yandexTravelIcon from "/assets/icons/ya_travel.svg";
import tripadvisorIcon from "/assets/icons/tripadvisor-icon.svg";
import ostrovokIcon from "/assets/icons/logo-ostrovok.svg";
import visaIcon from "/assets/icons/visa.svg";
import mirIcon from "/assets/icons/mir.svg";
import masterCardIcon from "/assets/icons/master-card.svg";
import payKeeperIcon from "/assets/icons/pay-keeper.svg";
import sbpIcon from "/assets/icons/sbp-logo.svg";
import unionPayIcon from "/assets/icons/union-pay.svg";
import './Footer.less';
import { RoutesEnum } from '../../routes.ts';

export function Footer() {
  const navigate = useNavigate();
  const handleNavItemClick = (url: string) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    navigate(url);
  }

  return (
    <footer className="footer">
      <div className="footer__container">
        <nav className="footer__nav">
          <button className="footer__nav-item footer__nav-item_logo"
                  onClick={() => handleNavItemClick(RoutesEnum.MAIN)}>
            <img height="60" src={logoFooter} alt="Tarleon logo"/>
          </button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.ABOUT)}>Об отеле</button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.ROOMS)}>Номера</button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.ROOMS_HOURS)}>На час</button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.OFFERS)}>Спецпредложения
          </button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.REVIEWS)}>Отзывы</button>
          <button className="footer__nav-item" onClick={() => handleNavItemClick(RoutesEnum.CONTACTS)}>Контакты</button>
        </nav>
        <section className="footer__contacts">
          <h3 className="footer__contacts-title">Контакты</h3>
          <p className="footer__contacts-item">
            <a className="footer__nav-item" href="https://yandex.ru/maps/org/mini_otel_tarleon/1688120118/" target="_blank">
              <img className="footer__nav-item-icon" src="/assets/icons/location.svg" alt="" />г. Москва, ул. Авиамоторная 44, стр.1.
            </a>
          </p>
          <p className="footer__contacts-item">
            <a className="footer__nav-item" href="tel:+79263049532"><img className="footer__nav-item-icon" src="/assets/icons/phone.svg" alt="" />+7-926-304-95-32</a>
          </p>
          <p className="footer__contacts-item">
            <a className="footer__nav-item" href="tel:+74956731426"><img className="footer__nav-item-icon" src="/assets/icons/phone.svg" alt="" />+7-495-673-14-26</a>
          </p>
          <p className="footer__contacts-item">
          <a className="footer__nav-item" href="tel:+74956731549"><img className="footer__nav-item-icon" src="/assets/icons/phone.svg" alt="" />+7-495-673-15-49</a>
          </p>
          <p className="footer__contacts-item">
          <a className="footer__nav-item" href="mailto:79263049532@yandex.ru"><img className="footer__nav-item-icon" src="/assets/icons/email.svg" alt="" />79263049532@yandex.ru</a>
          </p>
        </section>
        <div className="footer__section">
          <section className="footer__socials">
            <h3 className="footer__socials-title">Мы на других ресурсах</h3>
            <nav className="footer__socials-links">
              <a className="footer__nav-item footer__socials-link"
                 href="https://travel.yandex.ru/hotels/moscow/mini-otel-tarleon/" target="_blank">
                <img className="footer__socials-icon" src={yandexTravelIcon} alt="мы на Я.Путешествия"/>
              </a>
              <a className="footer__nav-item footer__socials-link"
                 href="https://www.tripadvisor.ru/Hotel_Review-g298484-d12071945-Reviews-Tarleon_Mini_Hotel-Moscow_Central_Russia.html"
                 target="_blank">
                <img className="footer__socials-icon" src={tripadvisorIcon} alt="мы на tripadvisor"/>
              </a>
              <a className="footer__nav-item footer__socials-link"
                 href="https://ostrovok.ru/hotel/russia/moscow/mid8553609/minihotel_tarleon/?q=2395&dates=14.09.2024-15.09.2024&guests=2&sid=d0d73e2a-76f5-4c54-8187-a743abf370f7"
                 target="_blank">
                <img className="footer__socials-icon" src={ostrovokIcon} alt="мы на ostrovok"/>
              </a>
            </nav>
          </section>
          <section className="footer__payments">
            <h3 className="footer__payments-title">Мы принимаем</h3>
            <div className="footer__payments-icons">
              <img className="footer__payments-icon" src={mirIcon} alt="МИР"/>
              <img className="footer__payments-icon" src={visaIcon} alt="VISA"/>
              <img className="footer__payments-icon" src={masterCardIcon} alt="MASTERCARD"/>
              <img className="footer__payments-icon footer__payments-icon_pay-keeper" src={payKeeperIcon}
                   alt="PayKeeper"/>
              <img className="footer__payments-icon footer__payments-icon_pay-keeper" src={sbpIcon}
                   alt="СБП"/>
              <img className="footer__payments-icon" src={unionPayIcon}
                   alt="UnionPay"/>
            </div>
          </section>
        </div>

      </div>
      <div className="footer__container">
        <section className="footer__additional-information">
          <h3 className="footer__additional-information-title">Дополнительная информация</h3>
          <button className="footer__additional-information-item"
                  onClick={() => handleNavItemClick(RoutesEnum.AGREEMENT_DATA)}>
            Соглашение на обработку персональных данных
          </button>
          <button className="footer__additional-information-item"
                  onClick={() => handleNavItemClick(RoutesEnum.PAYMENT_METHODS)}>
            Способы оплаты
          </button>
          <Link to={`${RoutesEnum.ABOUT}#certificate-tarleon`} className="footer__additional-information-item">
            Свидетельство о присвоении категории
          </Link>
        </section>
        <section className="footer__legal-information">
          <h3 className="footer__legal-information-title">Юридическая информация</h3>
          <ul className="footer__legal-information-list">
            <li className="footer__legal-information-item">
              ИНН: 504105334139
            </li>
            <li className="footer__legal-information-item">
              ОГРНИП: 309501230300049
            </li>
            <li className="footer__legal-information-item">
              ОКПО: 0166899984
            </li>
            <li className="footer__legal-information-item">
              Индивидуальный предприниматель  Леонов Олег Иванович
            </li>
          </ul>
        </section>
      </div>
    </footer>
  )
}
