import './Arrows.less';
import {MouseEventHandler} from "react";

export const NextArrow = (props: { className?: string, style?: Record<string, string>, onClick?: (event: React.MouseEvent<Element>) => void }) => {
  const { onClick } = props;
  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <button onClick={handleClick} className={`arrow arrow_next`}>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
        <path
          d="M5.25922 6.00034L0 11.0257L1.02026 12L6.78853 6.48751C6.85557 6.42351 6.90875 6.34752 6.94504 6.26387C6.98132 6.18022 7 6.09055 7 6C7 5.90945 6.98132 5.81978 6.94504 5.73613C6.90875 5.65248 6.85557 5.57648 6.78853 5.51249L1.02026 0L0 0.975021L5.25922 6.00034Z"
          fill="#fff"/>
      </svg>
    </button>
  )
}
