import notFoundIcon from '/assets/icons/404.svg';
import { Link } from 'react-router-dom';
import './Error.less';

export const NotFound = () => {
  return (
    <div className="container">
      <section className="error">
        <img className="error__image" src={notFoundIcon} alt="404 ошибка" width="100%" height="auto"/>
        <h2 className="error__title">К сожалению страница, которую вы искали не существует или удалена. <br /><Link className="error__link" to={'/'}>На главную</Link></h2>
      </section>
    </div>
  )
}
