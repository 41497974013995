import './Review.less';
import { IReview } from "../../services";

export const Review = (review: IReview) => {
  const {userName, date, score, text, id} = review;

  return (
    <div className="review" key={id}>
      <div className="review__header">
        <h3 className="review__title">{userName}</h3>
        <p className="review__date">{date}</p>
        <p className="review__score">{score}</p>
      </div>
      <p className="review__text">{text}</p>
    </div>
  )
}
