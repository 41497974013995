import { Header } from "../Header/Header.tsx";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer/Footer.tsx";

export const Layout = () => {
  return (
    <>
      <Header />
      <main className="main">
        <h1 className="visually-hidden">Мини-отель Tarleon</h1>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
