import './Arrows.less';
import {MouseEventHandler} from "react";

export const PrevArrow = (props: { className?: string, style?: Record<string, string>, onClick?: (event: React.MouseEvent<Element>) => void }) => {
  const { onClick } = props;
  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <button onClick={handleClick} className={`arrow arrow_prev`}>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
        <path
          d="M1.74078 5.99966L7 0.974334L5.97974 -8.91942e-08L0.211469 5.51249C0.14443 5.57648 0.0912486 5.65248 0.0549632 5.73613C0.0186778 5.81978 5.32453e-07 5.90945 5.24537e-07 6C5.1662e-07 6.09055 0.0186777 6.18022 0.0549631 6.26387C0.0912485 6.34752 0.14443 6.42352 0.211469 6.48751L5.97974 12L7 11.025L1.74078 5.99966Z"
          fill="#fff"/>
      </svg>
    </button>
  )
}
