import './RoomsPage.less';
import {useEffect, useState} from "react";
import {IRoom, RoomsService} from "../../services";
import {Room} from "../../components";

export const RoomsPage = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);

  useEffect(() => {
    const roomsService = new RoomsService();

    roomsService
      .getRooms()
      .then(rooms => setRooms(rooms))
      .catch(err => {
        console.error(err);
      })
  }, [])

  return (
    <div className="container">
      <section className="rooms-page">
        <h2 className="rooms-page__title">Номера</h2>
        <div className="rooms-page__list">
          {rooms.map(room => <Room key={room.id} {...room} />)}
        </div>
      </section>
    </div>
  )
}
