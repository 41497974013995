import './Contacts.less';

export const Contacts = () => {
  return (
    <div className="container">
      <section className="contacts">
        <h2 className="contacts__title">Контакты</h2>
        <h3 className="contacts__subtitle">Как c нами связаться</h3>
        <ul className="contacts__list contacts__list_unordered">
          <li>Написать или позвонить в whatsApp на номер <a className="contacts__link" href="https://wa.me/+79263049532">+7-926-304-95-32</a>
          </li>
          <li>Позвонить по любому из номеров: <a className="contacts__link" href="tel:+79263049532">+7-926-304-95-32</a>, <a className="contacts__link" href="tel:+74956731426">+7-495-673-14-26</a> и <a className="contacts__link" href="tel:+74956731549">+7-495-673-15-49</a>
          </li>
          <li>Написать нам на почту: <a className="contacts__link"
                                        href="mailto:79263049532@yandex.ru">79263049532@yandex.ru</a></li>
        </ul>
        <h3 className="contacts__subtitle">Как до нас добраться</h3>
        <p className="contacts__text">Адрес отеля: <a className="contacts__link" href="https://yandex.ru/maps/org/mini_otel_tarleon/1688120118/"
                             target="_blank">
            г. Москва, ул. Авиамоторная 44, стр.1.
          </a>
        </p>
        <h4 className="contacts__list-title">Кратчайшим путем на метро:</h4>
        <ol className="contacts__list">
          <li>Доехать до станции метро Авиамоторная Калининско-Солнцевской линии (желтой)</li>
          <li>Выйти из 7 выхода</li>
          <li>Идем вперед по Авиамоторной улице до дома 44, стр. 1. Трехэтажное желтое здание огороженное забором по центру которого проход во двор</li>
          <li>Зайдя во двор вы слева от себя увидите вход в отель</li>
        </ol>
        <iframe
          className="contacts__map"
          src="https://yandex.ru/map-widget/v1/?ll=37.724329%2C55.743252&mode=routes&rtext=55.750859%2C37.717476~55.738974%2C37.719111&rtt=pd&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D1688120118&z=14.45"
          width="100%" height="500" frameBorder="0" allowFullScreen={true}></iframe>
      </section>
    </div>
  )
}
