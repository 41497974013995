export enum RoutesEnum {
  MAIN = '/',
  ABOUT = '/about',
  ROOMS = '/rooms',
  ROOMS_HOURS = '/rooms-hours',
  OFFERS = '/offers',
  REVIEWS = '/reviews',
  CONTACTS = '/contacts',
  AGREEMENT_DATA = '/agreement-data',
  PAYMENT_METHODS = '/payment-methods'
}
