export interface IReview {
  id: number;
  userName: string;
  date: string;
  score: number;
  text: string;
}

const REVIEWS: IReview[] = [
  {
    id: 7,
    userName: 'Саша Т.',
    text: 'Останавливался с подругой. Номера в идеальной чистоте, сразу видно что следят. Телевизор, душ, всё работает отлично, всем рекомендую, рад что в Москве есть отличные бюджетные отели, буду приезжать ещё.',
    date: 'сентябрь 2024',
    score: 10
  },
  {
    id: 9,
    userName: 'Мария Фильчикова',
    text: 'Очень отзывчивый персонал, расположение рядом с метро ,добраться можно куда угодно, для бюджетных номеров - общий с/у и душ. Очень тихо и спокойно. Вернусь ещё 💞',
    date: 'сентябрь 2024',
    score: 10
  },
  {
    id: 1,
    userName: 'Antonina',
    text: `Метро относительно недалеко. Можно пешком.
      
      Все ок,кровать нормальная, большая ванная. Для тех, кто хочет выспаться - в самый раз, тк номер достался без окон. Кому-то это может быть и минус. Большой телевизор напротив кровати.
      
      Быстро оформили, претензий нет
    `,
    date: 'июль 2024',
    score: 9.4
  },
  {
    id: 10,
    userName: 'Сергей Евтюшин',
    text: 'Удобное место для остановки,все в пешей доступности (метро ,магазины)демократичные цены.',
    date: 'июль 2024',
    score: 10,
  },
  {
    id: 11,
    userName: 'Ирина Лазаренко',
    text: 'Останавливались в этом отеле с семьей, нам все очень понравилось, в номере чисто, есть все необходимое, очень удобная кровать, белое пастельное белье, есть две кухни на которых можно приготовить, рядом с отелем автобусная остановка и пешком до метро очень легко дойти. Приветливый персонал. Обязательно будем приезжать еще в Тарлеон!!!',
    date: 'апрель 2024',
    score: 10,
  },
  {
    id: 12,
    userName: 'Алёна Карамышева',
    text: 'Отличный отель. Чисто, тепло, белое постельное белье, отзывчивый персонал. В комнатах есть все необходимое: посуда, чайник, туалетные принадлежности,полотенца, остальное можно взять на ресепшен. Всем рекомендую!',
    date: 'март 2024',
    score: 10,
  },
  {
    id: 8,
    userName: 'Игорь Баранов',
    text: 'Все отлично, номер с удобствами отличный, чисто, телевизор и интернет в наличии , матрас двухспальный , удобный , уборка регулярная. Администраторы отзывчивые . Расположение гостиницы удобно - близко к центру и парковка бесплатная. Номера разной категории - от эконом до люкса. Если честно, я не снимал эконом , но стандарт меня полностью устраивает. Не экономьте на своем комфорте и все вас будет устраивать ! Удачи и процветания этому месту !',
    date: 'февраль 2024',
    score: 10
  },
  {
    id: 13,
    userName: 'Софья Лазаренко',
    text: 'Нам все очень понравилось! Номер был чистый, персонал приветливый. Так же понравилось расположение, до центра Москвы всего 2 станции метро. За такую цену однозначно рекомендуем! Отдельное спасибо администратору Ирине, на протяжении нашего проживания решала бытовые вопросы, интересовалась нашим комфортом, подсказывала как добраться до нужного места на общественном транспорте. Обязательно вернемся к вам еще!;)',
    date: 'февраль 2024',
    score: 10
  },
  {
    id: 5,
    userName: 'Adventure20390276099',
    text: `Все просто отлично!!!
    
    Безусловно лучший отель. В отеле всегда чисто убрано. Постельное белье свежее, кровати предварительны перед заселением всегда обработонны антисептиком. Очень вкусный завтрак могут приготовить обслуживающий персонал отеля!!! Спасибо очень доволен!!!!`,
    date: 'сентябрь 2023',
    score: 10
  },
  {
    id: 6,
    userName: 'Olga',
    text: `Бюджетный отель для любого клиента: есть эконом-номера для семейных пар, есть комнаты для ночевки, есть улучшенные номера с собственным душем и TV. На свою цену отличный вариант. Единственный недостаток - в стандартном номере не было тапочек.`,
    date: 'август 2020',
    score: 9
  },
  {
    id: 4,
    userName: 'Alexander',
    text: `Отличное место за эти деньги, очень приятно было остановиться, спасибо!`,
    date: 'декабрь 2017',
    score: 10
  },
  {
    id: 3,
    userName: 'musatowvasya',
    text: `Будет повод - вернусь еще!

    Жил в общем 6 местном номере и ничего плохого сказать не могу. Несмотря на то, что отель относится к эконом классу, он очень уютный и чистый. Соседи мне попались адекватные, никакого быдла и прочего неблагоприятного контингента не наблюдалось. Да, может у отеля и есть незначительные недостатки, но радушность персонала и довольна демократичная стоимость проживания полностью покрывают все косяки.`,
    date: 'сентябрь 2017',
    score: 10
  },
  {
    id: 2,
    userName: 'Dima',
    text: `Спасибо всему персоналу отеля за работу. Отель удобно расположен по отношению к центру города. Сервис на уровне. Советую всем, кто проездом или надолго решил задержаться в Москве.`,
    date: 'январь 2017',
    score: 10
  }
];

export class ReviewsService {
  async getReviews(): Promise<IReview[]> {
    return Promise.resolve(REVIEWS);
  }
}
