import './RoomPage.less';
import {useEffect, useRef, useState} from "react";
import { IRoomWithGalleryAndBenefits, RoomsService } from "../../services";
import {useLocation, useNavigate} from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { NextArrow, PrevArrow } from "../../components";

export const RoomPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [room, setRoom] = useState<IRoomWithGalleryAndBenefits | null>(null);
  const [isFullScreenGalleryOpened, setIsFullScreenGalleryOpened] = useState(false);
  const [navGallery, setNavGallery] = useState<Slider | null>(null);
  const [navPopupGallery, setNavPopupGallery] = useState<Slider | null>(null);
  const sliderGalleryRef = useRef<Slider | null>(null);
  const sliderPopupGalleryRef = useRef<Slider | null>(null);
  const sliderGallerySettings: Settings = {
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    lazyLoad: 'progressive',
  }
  const sliderPopupGallerySettings: Settings = {
    dots: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
  }
  const sliderGalleryArrows = document.querySelectorAll('.room-page__gallery .arrow');

  const handleSliderGalleryClick = () => {
    sliderGalleryArrows.forEach((el) => {
      el.classList.add('hidden');
    })

    setIsFullScreenGalleryOpened(true);
  }

  const handleSliderPopupGalleryClick = () => {
    sliderGalleryArrows.forEach((el) => {
      el.classList.remove('hidden');
    })

    setIsFullScreenGalleryOpened(false);
  }

  useEffect(() => {
    const roomsService = new RoomsService();
    const id = location.pathname.split('/')[2];

    roomsService
      .getRoom(Number(id))
      .then(room => {
        if (room.priceForTwoHours) {
          const pageElement = document.querySelector('.room-page') as HTMLElement | null;

          pageElement?.style.setProperty('--gallery-height', '650px')
        }
        setRoom(room)
      })
      .catch(err => {
        console.error(err);
        navigate('/404');
      })
  }, [location, navigate])

  useEffect(() => {
    setNavGallery(sliderGalleryRef.current);
    setNavPopupGallery(sliderPopupGalleryRef.current);
  }, []);

  return (
    <div className="container">
      <section className="room-page">
        <h2 className="room-page__title">{room?.title}</h2>
        <div className="room-page__content">
          <div className="room-page__info">
            <div className="room-page__info-booking">
              <p className="room-page__price">Стоимость номера в сутки: <span
                className="room-page__price-value">{room?.priceDay} ₽</span></p>
              {room?.priceForTwoHours && (
                <>
                  <p className="room-page__price">Стоимость номера при почасовой аренде за первые 2 часа: <span
                    className="room-page__price-value">{room?.priceForTwoHours} ₽</span></p>
                  <p className="room-page__price">Стоимость дополнительного часа при почасовой аренде: <span
                    className="room-page__price-value">{room?.priceForExtraHour} ₽</span></p>
                </>
              )}
              <p>По телефону: <a className="room-page__link" href="tel:+79263049532">+7-926-304-95-32</a></p>
              <a href={room?.link ?? 'https://app.autoportye.ru/external_widget/v2/hotel/315'} target="_blank"
                 className="room-page__submit-button">Забронировать</a>
            </div>
            <div className="room-page__info-main">
              <h3 className="room-page__info-title">Услуги и удобства</h3>
              <ul className="room-page__benefits">
                {room?.benefits.map((benefit, index) => (
                  <li className="room-page__benefit" key={index} ><img src={benefit.icon} alt="" width="24" height="24" />{benefit.text}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="room-page__gallery">
            <Slider {...sliderGallerySettings} asNavFor={navPopupGallery ?? undefined}
                    ref={slider => (sliderGalleryRef.current = slider)}>
              {
                room?.gallery.map((image, index) => (
                  <img onClick={handleSliderGalleryClick} className="room-page__image" src={image} alt="Фото номера"
                       key={index}/>
                ))
              }
            </Slider>
          </div>
        </div>
        <div className="room-page__about">
          <h3 className="room-page__info-title">О номере</h3>
          <p className="room-page__text">Площадь номера: {room?.square} м<sup>2</sup></p>
          <p className="room-page__text">{room?.text}</p>
        </div>
      </section>
      <div className={`popup-gallery ${isFullScreenGalleryOpened ? 'popup-gallery_opened' : ''}`}
           onClick={handleSliderPopupGalleryClick}>
        <Slider asNavFor={navGallery ?? undefined} ref={slider => {
          sliderPopupGalleryRef.current = slider;
        }} {...sliderPopupGallerySettings}>
          {
            room?.gallery.map((image, index) => (
              <img className="popup-gallery__image" src={image} alt="Фото номера" key={index}
                   onClick={(e) => e.stopPropagation()}/>
            ))
          }
        </Slider>
        <button
          type="button"
          className="popup__btn-closed"
          aria-label="Кнопка закрытия всплывающего окна"
        ></button>
      </div>
    </div>
  )
}
