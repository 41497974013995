import notFoundIcon from '/assets/icons/404.svg';
import { Link } from 'react-router-dom';
import './Error.less';
import { Footer, Header } from "../../components";

export const ServerError = () => {
  return (
    <>
      <Header/>
      <main className="main">
        <h1 className="visually-hidden">Мини-отель Tarleon</h1>
        <div className="container">
          <section className="error">
            <img className="error__image" src={notFoundIcon} alt="500 ошибка" width="100%" height="auto"/>
            <h2 className="error__title">Кажется что-то пошло не так, мы уже разбираемся. <br/><Link
              className="error__link" to={'/'}>На главную</Link></h2>
          </section>
        </div>
      </main>
      <Footer/>
    </>
  )
}
