import './PaymentMethods.less';

export const PaymentMethods = () => {
  return (
    <div className="container container_payment-methods">
      <section className="payment-methods">
        <h2 className="payment-methods__title">Правила оплаты</h2>
        <div className="payment-methods__container">
          <div className="payment-methods__info">
            <p className="payment-methods__text">К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide,
              МИР.</p>
            <p className="payment-methods__text">Для оплаты товара банковской картой при оформлении заказа в
              интернет-магазине выберите способ оплаты: банковской картой.</p>
            <p className="payment-methods__text">При оплате заказа банковской картой, обработка платежа происходит на
              авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты: </p>
            <ul className="payment-methods__list">
              <li>тип карты</li>
              <li>номер карты</li>
              <li>срок действия карты (указан на лицевой стороне карты)</li>
              <li>Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
              <li>CVC2/CVV2 код</li>
            </ul>
          </div>
          <img className="payment-methods__image" src="/assets/images/card-info.png"
               alt="изображение в котором показано, какие части карты содержат нужную для платежа информацию"/>
        </div>
        <p className="payment-methods__text">Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически
          переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о
          правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>
        <p className="payment-methods__text">Безопасность обработки интернет-платежей через платежный шлюз банка
          гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением
          технологии шифрования TLS. Эта информация недоступна посторонним лицам.</p>
        <p className="payment-methods__text">Советы и рекомендации по необходимым мерам безопасности проведения платежей
          с использованием банковской карты:</p>
        <ul className="payment-methods__list">
          <li>берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине, ресторане,
            магазине и т.д.
          </li>
          <li>никогда не передавайте полный номер своей кредитной карты по телефону каким-либо лицам или компаниям</li>
          <li>всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком</li>
          <li>вводите реквизиты карты  только при совершении покупки. Никогда не указывайте их по каким-то другим причинам.</li>
        </ul>
      </section>
      <section className="cancel-service">
        <h2 className="cancel-service__title">Отказ от услуги</h2>
        <p className="cancel-service__text">
          Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав потребителей»
        </p>
        <ul className="cancel-service__list">
          <li>
            Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги;
          </li>
          <li>
            Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору потребовать:
            <ul className="cancel-service__sublist">
              <li>безвозмездного устранения недостатков;</li>
              <li>соответствующего уменьшения цены;</li>
              <li>возмещения понесенных им расходов по устранению недостатков своими силами или третьими лицами;</li>
            </ul>
          </li>
          <li>
            Потребитель вправе предъявлять требования, связанные с недостатками оказанной услуги, если они обнаружены в течение гарантийного срока, а при его отсутствии в разумный срок, в пределах двух лет со дня принятия оказанной услуги;
          </li>
          <li>
            Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если потребитель докажет, что они возникли до ее принятия им или по причинам, возникшим до этого момента.
          </li>
        </ul>
      </section>
    </div>
  )
}
