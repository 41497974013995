import './App.less';
import {Layout} from './components';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {
  About,
  AgreementData,
  Contacts,
  Main,
  NotFound,
  Offers,
  PaymentMethods,
  Reviews,
  RoomPage,
  RoomsHoursPage,
  RoomsPage,
  ServerError
} from './pages';
import {RoutesEnum} from "./routes.ts";


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ServerError />,
    children: [
      {
        path: RoutesEnum.MAIN,
        element: <Main />
      },
      {
        path: RoutesEnum.ABOUT,
        element: <About />
      },
      {
        path: RoutesEnum.ROOMS,
        element: <RoomsPage />
      },
      {
        path: RoutesEnum.ROOMS_HOURS,
        element: <RoomsHoursPage />
      },
      {
        path: `${RoutesEnum.ROOMS}/:roomId`,
        element: <RoomPage />
      },
      {
        path: RoutesEnum.OFFERS,
        element: <Offers />
      },
      {
        path: RoutesEnum.REVIEWS,
        element: <Reviews />
      },
      {
        path: RoutesEnum.CONTACTS,
        element: <Contacts />
      },
      {
        path: RoutesEnum.AGREEMENT_DATA,
        element: <AgreementData />
      },
      {
        path: RoutesEnum.PAYMENT_METHODS,
        element: <PaymentMethods />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
])

function App() {
  return (
    <div className="app">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App
