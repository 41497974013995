import './Room.less';
import { IRoom } from "../../services";
import { useNavigate } from "react-router-dom";
import {RoutesEnum} from "../../routes.ts";

export function Room(room: IRoom)  {
  const { title, id, text, priceDay, image = '', priceForTwoHours, link = 'https://app.autoportye.ru/external_widget/v2/hotel/315' } = room;
  const navigate = useNavigate();
  const handleNavItemClick = (url: string) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    navigate(url);
  }

  const lastClick = {
    x: 0,
    y: 0,
  };

  const handleMouseDown = ({ pageX, pageY }: React.MouseEvent) => {
    lastClick.x = pageX;
    lastClick.y = pageY;
  }

  const handleMouseUp = ({ pageX, pageY }: React.MouseEvent, link: string) => {
    if (lastClick.x === pageX && lastClick.y === pageY) {
      handleNavItemClick(link);
    }
  }

  return (
    <section className="room">
      <div className="room__content">
        <h3 className="room__title"><a className="room__link" onMouseDown={handleMouseDown} onMouseUp={(event) => handleMouseUp(event, `${RoutesEnum.ROOMS}/${id}`)}>{title}</a></h3>
        <p className="room__text">{text}</p>
        {room.isRoomsHoursPage ?
          <p className="room__price">{priceForTwoHours} ₽/2 часа</p>
          :
          <p className="room__price">{priceDay} ₽/сутки</p>
        }
        <a target="_blank" href={link} className="room__button">Забронировать</a>
      </div>
      <img className="room__image" onMouseDown={handleMouseDown} onMouseUp={(event) => handleMouseUp(event, `${RoutesEnum.ROOMS}/${id}`)} src={image} alt="фото номера"/>
    </section>
  )
}
