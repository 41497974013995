import { Review } from '../../components';
import {IReview, ReviewsService} from '../../services';
import './Reviews.less';
import {useEffect, useState} from "react";

export const Reviews = () => {
  const [reviews, setReviews] = useState<IReview[]>([]);

  useEffect(() => {
    const reviewsService = new ReviewsService();

    reviewsService
      .getReviews()
      .then(reviews => setReviews(reviews))
      .catch(err => {
        console.error(err);
      })
  }, [])

  return (
    <div className="container">
      <section className="reviews">
        <h2 className="reviews__title">Отзывы</h2>
        <div className="reviews__list">
          {reviews.map(review => <Review key={review.id} {...review} />)}
        </div>
      </section>
    </div>
  )
}
