import './About.less';

export const About = () => {
  return (
    <div className="container">
      <section className="about-page">
        <h2 className="about-page__title">Об отеле</h2>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Расположение</h3>
          <p className="about-page__text">Мини-отель «ТАРЛЕОН» находится в историческом районе Москвы "Лефортово".
            Недалеко от мини-отеля расположены
            такие достопримечательности как Музей-заповедник "Лефортово", Лефортовский парк, Немецкая слобода,
            Екатерининский дворец. В 2-х минутах ходьбы находится бассейн, большой торговый комплекс с кинотеатром,
            магазинами и уютными кафе, где Вы сможете недорого и вкусно поесть.</p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">В мини-отеле</h3>
          <p className="about-page__text">Хотите оставаться на связи? В мини-отеле есть бесплатный Wi-Fi. Специально для
            автопутешественников организована бесплатная парковка. Берите питомца с собой. В мини-отеле возможно размещение с домашним любимцем.</p>
          <p className="about-page__text">Для простоты
            передвижения возможна организация трансфера. А ещё в распоряжении гостей гладильные услуги. Персонал
            мини-отеля говорит на английском и русском.</p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Заселение и выезд</h3>
          <ul className="about-page__list">
            <li>Заселение от 14:00 до 00:00 по местному времени</li>
            <li>Выезд до 12:00 по местному времени</li>
          </ul>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Оплата</h3>
          <h4 className="about-page__info-block-subtitle">В мини-отеле</h4>
          <p className="about-page__text">Оплата принимается в RUB наличными или картой. Принимаются карты платежных
            систем - Visa, MasterCard и МИР. Так же возможна оплата по qr-коду и СБП.</p>
          <h4 className="about-page__info-block-subtitle">На сайте</h4>
          <p className="about-page__text">Доступна оплата банковской картой при бронировании на сайте. Так же возможна
            оплата по qr-коду и СБП.</p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Правила отмены бронирования</h3>
          <p className="about-page__text">Бесплатная отмена вашего бронирования возможна до 15:00 (местное время
            Москва), не менее чем за сутки до заселения. Отмена бронирования после этого времени или неприбытие в отель
            влечёт комиссию в размере стоимости одной ночи проживания. Неприбытие или отсутствие предупреждения о
            неприбытии по телефону до времени вселения после первой ночи брони влечет за собой отмену всей оставшейся
            брони.</p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Какие документы нужны при заселении?</h3>
          <p className="about-page__text">Для граждан РФ – паспорт.</p>
          <p className="about-page__text">Для иностранных граждан – действующий паспорт с визой и миграционной
            картой.</p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Возможен ли ранний заезд и поздний выезд?</h3>
          <p className="about-page__text">
            Да.
            Ранний заезд без дополнительной оплаты возможен при наличии свободных номеров, соответствующее решение
            принимают сотрудники службы размещения в день вашего заезда. В случае высокой загрузки отеля сотрудники
            службы размещения в праве предложить услугу раннего заезда за дополнительную плату.
          </p>
          <p className="about-page__text">Если вам нужно заранее забронировать гарантированный ранний заезд, необходимо
            внести оплату равную полной
            стоимости предыдущих суток.</p>
          <p className="about-page__text">В случае заезда после 00:00 гостям необходимо заранее проинформировать отель о
            времени своего прибытия. Контактная информация указана в подтверждении бронирования.
          </p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Какие условия размещения с детьми?</h3>
          <p className="about-page__text">
            Дети в возрасте до 18 лет размещаются в Отеле в сопровождении родителей (опекунов, совершеннолетних
            сопровождающих).
          </p>
          <p className="about-page__text">По вашей просьбе может быть организовано спальное место для ребенка:
          </p>
          <ul className="about-page__list">
            <li>До 3 лет - в детской кроватке или люльке бесплатно.</li>
            <li>До 7 лет - размещение на кровати вместе с родителями бесплатно.</li>
            <li>С 7 до 14 лет - на дополнительном месте в номере, 1000 рублей в сутки.</li>
          </ul>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Можно ли проживать с животными?</h3>
          <p className="about-page__text">
            Да. Проживание с домашними животными: кошками, собаками и птицами возможно.
          </p>
          <p className="about-page__text">
            При заезде необходимо оплатить стоимость размещения: 1 000 рублей в сутки.
          </p>
          <p className="about-page__text">
            Перед заездом ознакомьтесь с правилами проживания с питомцами:
          </p>
          <ul className="about-page__list">
            <li>В общественных местах на территории отеля животное выше 60см в холке держите в наморднике и на поводке, а
              птицу - в клетке.</li>
            <li>В номере птица должна находиться в клетке.</li>
            <li>Питомец может оставаться в номере только под вашим присмотром.</li>
            <li>Необходимо соблюдать чистоту в номере и на территории отеля.</li>
            <li>Количество домашних животных для размещения зависит от количества комнат в номере: в одной комнате может
              проживать одно животное.</li>
            <li>Гость, проживающий с животным, берет на себя обязательство соблюдать санитарно-гигиенические условия в
              номере и на территории отеля.</li>
          </ul>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Можно ли курить в номерах и на территории отеля?</h3>
          <p className="about-page__text">
            Нет. В соответствии с Законом об охране здоровья граждан курить в номерах гостиниц и отелей запрещено.
          </p>
        </div>
        <div className="about-page__info-block">
          <h3 className="about-page__info-block-title">Дополнительная информация</h3>
          <p className="about-page__text">
            Тариф 15 суток и 30 суток действует только при единовременной оплате.
          </p>
          <p className="about-page__text">
            Заселение после 14:00 часов. Выезд до 12:00. Плата за проживание в гостинице взимается в соответствии с
            единым расчетным часом по местному времени до 12:00 часов суток, предшествующих суткам проживания.
          </p>
          <p className="about-page__text">
            За тарифы и условия бронирований, а также оплату проживания, совершенные через сайты посредников,
            администрация отеля ответственность не несёт.
          </p>
        </div>
      </section>
      <embed id="#certificate-tarleon" src="/assets/images/certificate-tarleon.pdf" type="application/pdf" className="about-page__certificate"/>
    </div>
  )
}
