const BASE_URL = '/assets/icons';

export interface IBenefit {
  icon: string;
  text: string;
}

export const BENEFITS: Record<string, IBenefit> = {
  CONDITIONER: { icon: `${BASE_URL}/conditioner.svg`, text: 'Кондиционер' },
  TV: { icon: `${BASE_URL}/tv.svg`, text: 'Телевизор' },
  OWNED_BATHROOM: { icon: `${BASE_URL}/bath.svg`, text: 'Собственная ванная комната' },
  SHOWER: { icon: `${BASE_URL}/shower.svg`, text: 'Душ' },
  DESK: { icon: `${BASE_URL}/desk.svg`, text: 'Стол' },
  CLOSET: { icon: `${BASE_URL}/closet.svg`, text: 'Шкаф' },
  SHARED_BATHROOM: { icon: `${BASE_URL}/bath-off.svg`, text: 'Общая ванная комната' },
  COMMON_DINING_AREA: { icon: `${BASE_URL}/dining-area.svg`, text: 'Общая обеденная зона' },
  IRONING_ACCESSORIES: { icon: `${BASE_URL}/ironing-accessories.svg`, text: 'Гладильные принадлежности' },
  BOTTLED_WATER: { icon: `${BASE_URL}/bottled-water.svg`, text: 'Бутилированная вода' },
  SLIPPERS: { icon: `${BASE_URL}/slippers.svg`, text: 'Тапочки' },
  SAFE: { icon: `${BASE_URL}/safe.svg`, text: 'Сейф' },
  DOUBLE_BED: { icon: `${BASE_URL}/double-bed.svg`, text: 'Двуспальная кровать' },
  BUNK_BED: { icon: `${BASE_URL}/single-bed.svg`, text: 'Двухъярусная кровать' },
  DIFFERENT_TYPES_OF_BEDS: { icon: `${BASE_URL}/single-bed.svg`, text: 'Различные типы кроватей' },
  TWO_SINGLE_BEDS: { icon: `${BASE_URL}/single-bed.svg`, text: 'Две односпальные кровати' },
  SOFA: { icon: `${BASE_URL}/sofa.svg`, text: 'Диван' },
  TELEPHONE: { icon: `${BASE_URL}/telephone.svg`, text: 'Телефон' },
  TOILETRIES: { icon: `${BASE_URL}/sanitizer.svg`, text: 'Туалетные принадлежности' },
}
