import './Header.less';
import {useLayoutEffect, useState} from "react";
import logo from '/assets/icons/logo-header.svg';
import {useNavigate} from "react-router-dom";
import { RoutesEnum } from '../../routes.ts';

function NavMenu({ onClick }: { onClick: (url: string) => void }) {
  return (
    <>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.ABOUT)}>Об отеле</button>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.ROOMS)}>Номера</button>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.ROOMS_HOURS)}>На час</button>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.OFFERS)}>Спецпредложения</button>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.REVIEWS)}>Отзывы</button>
      <button className="header__nav-item" onClick={() => onClick(RoutesEnum.CONTACTS)}>Контакты</button>
    </>
  )
}

export function Header() {
  const [isOpenNavMenu, setIsOpenNavMenu] = useState(false);
  const [size, setSize] = useState(document.documentElement.scrollWidth);
  const navigate = useNavigate();
  const handleNavItemClick = (url: string) => {
    setIsOpenNavMenu(false);
    navigate(url);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setSize(document.documentElement.scrollWidth)
    })
  }, []);

  return (
    <header className="header">
      <nav className="header__nav">
        <button className="header__nav-item header__nav-item_logo" onClick={() => handleNavItemClick(RoutesEnum.MAIN)}>
          <img height="60" src={logo} alt="Tarleon logo" />
        </button>
        {
          size > 650 ?
            (
              <NavMenu onClick={handleNavItemClick} />
            ) : (
              <button
                className={`header__nav-button${isOpenNavMenu ? ' header__nav-button_open' : ''}`}
                onClick={() => setIsOpenNavMenu(!isOpenNavMenu)}
              ></button>
            )
        }
        {isOpenNavMenu && (
          <div className="header__nav-section">
            <NavMenu onClick={handleNavItemClick} />
          </div>
        )}
      </nav>
    </header>
  )
}
