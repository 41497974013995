import '../RoomsPage/RoomsPage.less';
import {useEffect, useState} from "react";
import {IRoom, RoomsService} from "../../services";
import {Room} from "../../components";

export const RoomsHoursPage = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);

  useEffect(() => {
    const roomsService = new RoomsService();

    roomsService
      .getHoursRooms()
      .then(rooms => setRooms(rooms))
      .catch(err => {
        console.error(err);
      })
  }, [])

  return (
    <div className="container">
      <section className="rooms-page">
        <h2 className="rooms-page__title rooms-page__title_hours">На час</h2>
        <p className="rooms-page__subtitle">Цена указана за первые два часа аренды, каждый последующий час стоит дополнительно 500 ₽. Более подробную информацию по вариантам бронирования можно посмотреть на странице интересующего вас номера.</p>
        <div className="rooms-page__list">
          {rooms.map(room => ({...room, isRoomsHoursPage: true})).map(room => <Room key={room.id} {...room} />)}
        </div>
      </section>
    </div>
  )
}
